import { useEffect, useMemo, useState } from 'react'
import { createSocket, SOCKET_EVENT_CONNECT, SOCKET_EVENT_DISCONNECT, SOCKET_EVENT_MESSAGE_QUOTA_UPDATE, SOCKET_EVENT_USER_CONNECTED } from '../socket/socket-io'
import { useAuth } from '../contexts/auth'

const useMessageQuota = () => {
  const [webSocket, setWebSocket] = useState(null)
  const { auth, setAuthUser } = useAuth()

  useEffect(() => {
    const socket = createSocket()
    socket.connect()
    setWebSocket(socket)
    return () => {
      socket.disconnect()
      setWebSocket(null)
    }
  }, [])

  useEffect(() => {
    if (webSocket && auth.user) {
      webSocket.on(SOCKET_EVENT_CONNECT, () => {
        webSocket.emit(SOCKET_EVENT_USER_CONNECTED, auth.user.user_id)
      })

      webSocket.on(SOCKET_EVENT_DISCONNECT, () => {})

      webSocket.on(SOCKET_EVENT_MESSAGE_QUOTA_UPDATE, (messageQuotaUpdate) => {
        setAuthUser((oriUser) => ({
          ...oriUser,
          ...messageQuotaUpdate,
        }))
      })

      webSocket.io.on('error', (err) => {
        console.error('err', err)
      })
    }
    return () => {
      if (webSocket && auth.user) {
        webSocket.off()
      }
    }
  }, [auth.user, webSocket, setAuthUser])

  const messageQuota = auth.user?.message_quota || 0
  const messageRemainging = auth.user?.message_remaining || 0

  return useMemo(() => ({
    quota: messageQuota,
    remaining: messageRemainging,
  }), [messageQuota, messageRemainging])
}

export default useMessageQuota

import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { size } from 'styled-theme'
import Text from '../../atoms/Text'
import Viewport from '../../atoms/Viewport'
import ContentBox from '../../atoms/ContentBox'
import Modal from '../../molecules/Modal'
import MessageQuotaBox from '../../molecules/MessageQuotaBox'
import AddSenseGroupView from '../../molecules/AddSenseGroupView'
import TopUpPayment from '../../organisms/TopUpPayment'
import EditProfileForm from '../../organisms/EditProfileForm'
import useMessageQuota from '../../../hooks/useMessageQuota'
import { useBuyMeACoffeeWidget } from '../../../services/buymeacoffee'
import { getStoredAuth } from '../../../api'
import { getMe, updateUser } from '../../../api/users'
import { requestVerifyEmail } from '../../../api/auth'
import { handleErrorResponse } from '../../../utlils/general'
import { useLoading } from '../../../contexts/loading'
import { useAuth } from '../../../contexts/auth'
import { getErrorMessage } from '../../../utlils/form'
import AvatarSection from './AvatarSection'

const StyledViewport = styled(Viewport)`
  padding: 4.5rem 3rem 3rem;
  align-items: flex-start;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 4rem 0 0;
  }
`

const ProfilePage = () => {
  const [topUpModalOpen, setTopUpModalOpen] = useState(false)
  const [topUpMessage, setTopUpMessage] = useState(null)
  const { quota: messageQuota, remaining: messageRemainging } = useMessageQuota()
  const { auth, setAuth } = useAuth()
  const { setLoading } = useLoading()

  useBuyMeACoffeeWidget()

  const fetchUserFromApi = useCallback(() => {
    const userParams = {}
    setLoading(true)
    return getMe(userParams)
      .then(({ data: user }) => {
        const newAuth = { ...getStoredAuth(), user }
        setAuth(newAuth)
      })
      .catch(((err) => {
        handleErrorResponse(err, setAuth)
      }))
      .finally(() => {
        setLoading(false)
      })
  }, [setLoading, setAuth])

  const updateUserToApi = useCallback((values, { setFieldError }) => {
    const updateBody = {
      username: values.username,
      email: values.email,
      display_name: values.display_name,
      sex: values.sex,
      phone_number: values.phone_number,
      date_of_birth: values.date_of_birth ? moment(values.date_of_birth).valueOf() : null,
      height: values.height,
      weight: values.weight,
      target_sex: values.target_sex,
      contact_info: values.contact_info,
      description: values.description,
      active: values.active,
    }
    setLoading(true)
    return updateUser(auth.user?.username, updateBody)
      .then(({ data: updatedUser }) => {
        const newAuth = { ...getStoredAuth(), user: updatedUser }
        setAuth(newAuth)
      })
      .catch(((err) => {
        setFieldError('form', getErrorMessage(err))
        handleErrorResponse(err, setAuth)
      }))
      .finally(() => {
        setLoading(false)
      })
  }, [auth.user, setLoading, setAuth])

  const sendVerifyEmailRequestToApi = useCallback(() => {
    setLoading(true)
    return requestVerifyEmail()
      .then(({ data }) => {
        if (data.status === 'success') {
          alert('驗證電郵已發送到你的E-mail，請檢查郵箱📪')
        }
      })
      .catch(((err) => {
        handleErrorResponse(err, setAuth)
      }))
      .finally(() => {
        setLoading(false)
      })
  }, [setAuth, setLoading])

  useEffect(() => {
    if (!topUpModalOpen) {
      // Reset the top up message to default one
      setTimeout(() => {
        setTopUpMessage(null)
      }, 150)
    }
  }, [topUpModalOpen])

  useEffect(() => {
    fetchUserFromApi()
  }, [fetchUserFromApi])

  return (
    <StyledViewport>
      <ContentBox>
        <Flex justifyContent="flex-end" marginRight="-1.5rem" marginTop="-1.5rem" marginBottom="0.5rem">
          <MessageQuotaBox
            quota={messageQuota}
            remaining={messageRemainging}
            onClickTopUp={() => setTopUpModalOpen(true)}
          />
        </Flex>
        <Flex justifyContent="center" mb="1rem">
          <Text palette="primary" bold variant="h1">個人資料</Text>
        </Flex>
        <Flex width={1} justifyContent="center" mb="1.5rem">
          <AvatarSection
            onTopUp={() => {
              setTopUpMessage('你嘅用量不足！\n請先增值所需要嘅用量！')
              setTopUpModalOpen(true)
            }}
          />
        </Flex>
        <EditProfileForm
          user={auth.user}
          onSave={updateUserToApi}
          onRequestVerifyEmail={sendVerifyEmailRequestToApi}
        />
      </ContentBox>
      <Modal
        showCloseBtn
        isOpen={topUpModalOpen}
        onClose={() => setTopUpModalOpen(false)}
        style={{ width: '100%', maxWidth: 360 }}
      >
        <Flex mb="1rem" width={1}>
          <TopUpPayment
            message={topUpMessage || '想同Crush仔傾多啲？\n你可以根據個人需要選擇你想要增值嘅用量！'}
            onComplete={() => setTopUpModalOpen(false)}
          />
        </Flex>
      </Modal>
      <AddSenseGroupView />
    </StyledViewport>
  )
}

export default ProfilePage

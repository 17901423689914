import React, { useCallback, useState } from 'react'
import { Flex } from '@rebass/grid'
import PropTypes from 'prop-types'
import Text from '../../atoms/Text'
import Image from '../../atoms/Image'
import Button from '../../atoms/Button'
import StripePaymentForm from '../../molecules/StripePaymentForm'
import { createPayment } from '../../../api/payments'
import { handleErrorResponse } from '../../../utlils/general'

const TopUpPayment = ({ message, onComplete }) => {
  const [stripePaymentClientSecret, setStripePaymentClientSecret] = useState(null)
  const [selectedQuota, setSelectedQuota] = useState(null)
  const [loading, setLoading] = useState(false)

  const onClickAddValue = useCallback((quota) => {
    setLoading(true)
    setSelectedQuota(quota)
    const amountRequired = quota * 100
    return createPayment({ amount: amountRequired })
      .then(({ data }) => {
        const { stripe_client_secret } = data
        setStripePaymentClientSecret(stripe_client_secret)
      })
      .catch((err) => {
        handleErrorResponse(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [setLoading])

  return (
    <Flex width={1} flexDirection="column" style={{ position: 'relative' }}>
      <Flex flexDirection="column" alignItems="center" mb="1rem">
        <Text palette="primary" bold variant="h2">增值服務</Text>
      </Flex>
      {stripePaymentClientSecret ? (
        <Flex flexDirection="column" alignItems="center">
          <Text variant="body1" align="center">
            即將為你增值 <Text palette="primary" bold>{selectedQuota}</Text> 個用量
          </Text>
          <Flex p="0.5rem" justifyContent="center" mt="1rem">
            <StripePaymentForm clientSecret={stripePaymentClientSecret} onConfirmSuccess={onComplete} />
          </Flex>
        </Flex>
      ) : (
        <Flex flexDirection="column" justifyContent="center">
          <Text variant="body1" align="center" style={{ marginBottom: '1rem' }}>
            {message}
          </Text>
          {[5, 10, 20].map((quota, aIdx) => (
            <Button
              key={aIdx}
              fullWidth
              disabled={loading}
              onClick={() => onClickAddValue(quota)}
              style={{ marginTop: '1rem' }}
            >
              {quota} 個用量&nbsp;&nbsp;&nbsp;(💰: ${quota})
            </Button>
          ))}
        </Flex>
      )}
      {loading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          style={{
            position: 'fixed',
            inset: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
          }}
        >
          <Image src="/logo_simple.png" height="3rem" className="self_rotate" />
        </Flex>
      )}
    </Flex>
  )
}

TopUpPayment.propTypes = {
  message: PropTypes.string,
  onComplete: PropTypes.func,
}

TopUpPayment.defaultProps = {
  message: '請選擇你想要增值嘅用量',
  onComplete: () => {},
}

export default TopUpPayment

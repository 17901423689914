import React, { useMemo } from 'react'
import get from 'lodash/get'
import styled from 'styled-components'
import { palette, size } from 'styled-theme'
import { IoArrowBack } from 'react-icons/io5'
import { Flex } from '@rebass/grid'
import { useParams } from 'react-router-dom'
import Chatroom from '../../organisms/Chatroom'
import Text from '../../atoms/Text'
import Button from '../../atoms/Button'
import Viewport from '../../atoms/Viewport'
import ContentBox from '../../atoms/ContentBox'
import AddSenseGroupView from '../../molecules/AddSenseGroupView'
import useChatMessages from '../../../hooks/useChatMessages'
import { useAuth } from '../../../contexts/auth'

const StyledViewport = styled(Viewport)`
  padding: 4.5rem 1rem 3rem;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 4rem 0 0;
  }
`

const ChatHeader = styled(Flex)`
  align-items: center;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  padding: 0.8rem;
  border-bottom: 4px solid ${palette('primary', 0)};
`

const StyledContentBox = styled(ContentBox)`
  height: 100%;
  padding: 0.5rem;
`

const ChatPage = () => {
  const { auth } = useAuth()
  const { chatId } = useParams()
  const { chat: currentChat, messages, sendChatMessage } = useChatMessages(chatId)

  const otherChatUsers = useMemo(() => {
    if (!currentChat) {
      return []
    }
    return (currentChat.chat_members || []).reduce((users, chatMember) => {
      const { user } = chatMember
      if (user.user_id !== auth.user?.user_id) {
        return [...users, user]
      }
      return users
    }, [])
  }, [currentChat, auth.user])

  return (
    <StyledViewport>
      <StyledContentBox>
        <ChatHeader>
          <Button variant="text" to="/matches" padding="0.125rem" style={{ marginRight: '0.5rem' }}>
            <IoArrowBack size={24} />
          </Button>
          {otherChatUsers.length > 0 && (
            <Text variant="h3" bold>
              {otherChatUsers.map(({ display_name }) => display_name).join(', ')}
            </Text>
          )}
        </ChatHeader>
        <Chatroom
          flex={1}
          style={{ minHeight: 0 }}
          userId={get(auth.user, 'user_id')}
          messages={messages}
          readOnly={!currentChat || !get(auth.user, 'active') || !get(auth.user, 'email_verified')}
          onMessageSend={sendChatMessage}
          headerHidden
        />
      </StyledContentBox>
      <AddSenseGroupView />
    </StyledViewport>
  )
}

export default ChatPage

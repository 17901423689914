import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Formik } from 'formik'
import { Box, Flex } from '@rebass/grid'
import { useTheme } from 'styled-components'
import Text from '../../atoms/Text'
import Input from '../../atoms/Input'
import Image from '../../atoms/Image'
import Button from '../../atoms/Button'
import Checkbox from '../../atoms/Checkbox'
import CheckboxSelect from '../../molecules/CheckboxSelect'
import { datetimeFormatter } from '../../../utlils/datetime'
import iconVerified from '../../../assets/images/verified.svg'

const sexOptions = [
  { label: '男', value: 'M' },
  { label: '女', value: 'F' },
]

const formValidation = (values) => {
  const { username, email, display_name, sex, phone_number, contact_info } = values
  const errors = {}
  if (!username) {
    errors.username = '必須填寫'
  } else if (username.length < 6) {
    errors.username = '必須長過6個字元'
  } else if (!/^[a-zA-Z0-9]+$/.test(username)) {
    errors.username = '必須由英文字母或數字組成'
  }
  if (!email) {
    errors.email = '必須填寫'
  }
  if (!display_name) {
    errors.display_name = '必須填寫'
  }
  if (!sex) {
    errors.sex = '必須填寫'
  } else if (!['M', 'F'].includes(sex)) {
    errors.sex = '必須為 "M" 或 "F"'
  }
  if (!phone_number) {
    errors.phone_number = '必須填寫'
  } else if (!/^([+]?852)?[ -]*[2-9][0-9]{3}[ -]*[0-9]{4}$/.test(phone_number)) {
    errors.phone_number = '電話號碼不正確'
  }
  return errors
}

const EditProfileForm = ({ user, onSave, onRequestVerifyEmail, ...props }) => {
  const [verifyRequestSent, setVerifyRequestSent] = useState(false)
  const theme = useTheme()

  const initialValues = {
    username: user.username,
    email: user.email,
    display_name: user.display_name,
    sex: user.sex,
    phone_number: user.phone_number,
    date_of_birth: user.date_of_birth ? datetimeFormatter(user.date_of_birth) : null,
    height: user.height,
    weight: user.weight,
    target_sex: user.target_sex,
    contact_info: user.contact_info,
    description: user.description,
    active: user.active,
  }

  const handleClickRequestVerifyEmail = useCallback(() => {
    return Promise.resolve(onRequestVerifyEmail())
      .then(() => {
        setVerifyRequestSent(true)
      })
  }, [onRequestVerifyEmail])

  return (
    <Formik
      validate={formValidation}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSave}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, touched, dirty }) => {
        const renderFormField = (fieldName, label, type, extraProps) => (
          <Box mt="0.6rem" flex={1}>
            <Input
              name={fieldName}
              label={get(values, fieldName) ? label : null}
              placeholder={label}
              value={get(values, fieldName) || ''}
              onChange={handleChange(fieldName)}
              onBlur={handleBlur(fieldName)}
              invalid={touched[fieldName] && !!errors[fieldName]}
              type={type}
              {...extraProps}
            />
            <Box ml="0.8rem">
              <Text variant="body3" palette="error">{touched[fieldName] && !!errors[fieldName] ? errors[fieldName] : ' '}</Text>
            </Box>
          </Box>
        )
        return (
          <form onSubmit={handleSubmit}>
            {renderFormField('username', '用戶ID *', 'text', { readOnly: true })}
            <Flex style={{ position: 'relative' }}>
              {renderFormField('email', 'E-mail *', 'email', { readOnly: true })}
              <Flex flexDirection="column" mt="0.6rem" style={{ position: 'absolute', top: 0, bottom: 0, right: 0 }}>
                <Flex flex={1} alignItems="center" px="0.4rem">
                  {user.email_verified ? (
                    <Image src={iconVerified} width={24} height={24} style={{ marginRight: '0.4rem' }} />
                  ) : (
                    <Button
                      onClick={handleClickRequestVerifyEmail}
                      disabled={verifyRequestSent}
                      padding="0.5rem"
                    >
                      <Text variant="body2" palette="white">驗證</Text>
                    </Button>
                  )}
                </Flex>
                <Box>
                  <Text variant="body3">{' '}</Text>
                </Box>
              </Flex>
            </Flex>
            {renderFormField('display_name', '顯示名稱 *')}
            <Box my="0.4rem">
              <Flex alignItems="center">
                <Text palette="primary" paletteIndex={4}>性別 *</Text>
                <Flex ml="2rem">
                  <CheckboxSelect
                    value={values.sex}
                    options={sexOptions}
                    onChange={(v) => setFieldValue('sex', v)}
                    onBlur={handleBlur('sex')}
                    invalid={touched.sex && !!errors.sex}
                  />
                </Flex>
              </Flex>
              <Box ml="5rem">
                <Text variant="body3" palette="error">{touched.sex && !!errors.sex ? errors.sex : ' '}</Text>
              </Box>
            </Box>
            {renderFormField('phone_number', '電話號碼 *', 'tel')}
            {renderFormField('date_of_birth', '生日日期', 'date', { label: '生日日期' })}
            {renderFormField('height', '身高 (cm)', 'number', { min: 0, step: 'any' })}
            {renderFormField('weight', '體重 (kg)', 'number', { min: 0, step: 'any' })}
            <Box my="0.4rem">
              <Flex alignItems="center">
                <Text palette="primary" paletteIndex={4}>你想搵...</Text>
                <Flex ml="2rem">
                  <CheckboxSelect
                    value={values.target_sex}
                    options={sexOptions}
                    onChange={(v) => setFieldValue('target_sex', v)}
                    onBlur={handleBlur('target_sex')}
                    invalid={touched.target_sex && !!errors.target_sex}
                  />
                </Flex>
              </Flex>
              <Box ml="5rem">
                <Text variant="body3" palette="error">{touched.target_sex && !!errors.target_sex ? errors.target_sex : ' '}</Text>
              </Box>
            </Box>
            {/* {renderFormField('contact_info', '聯絡方法 (會被成功配對用家看到)', 'textarea', { rows: 3, placeholder: '聯絡方法 (會被成功配對用家看到)\n( 例如 tg: hugo1991, IG: abc_123, WhatsApp: 1234 5678 )' })} */}
            {renderFormField('description', '個人簡介', 'textarea', { rows: 10, placeholder: '個人簡介\n( 提示：打得愈多野愈易比人搵到你！)' })}
            <Flex
              my="0.4rem"
              alignItems="center"
              style={{ border: '2px dashed', borderColor: theme.palette.secondary[0], padding: '1rem', borderRadius: '0.5rem' }}
            >
              <Text palette="primary" bold style={{ whiteSpace: 'nowrap' }}>啟用</Text>
              <Flex ml="2rem" alignItems="center">
                <Checkbox
                  name="active"
                  checked={values.active}
                  onChange={(v) => setFieldValue('active', v)}
                  onBlur={handleBlur('active')}
                  invalid={touched.active && !!errors.active}
                />
                {!values.active && (
                  <Box ml="1rem">
                    <Text palette="primary" paletteIndex={0}>👈 要tick左佢人地先可以搵到你架！</Text>
                  </Box>
                )}
              </Flex>
            </Flex>
            {!!errors.form && (
              <Text palette="error">{errors.form}</Text>
            )}
            <Flex mt="2rem">
              <Button
                type="submit"
                disabled={!dirty || isSubmitting}
                fullWidth
              >
                儲存
              </Button>
            </Flex>
          </form>
        )
      }}
    </Formik>
  )
}

EditProfileForm.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    username: PropTypes.string,
    display_name: PropTypes.string,
    description: PropTypes.string,
    sex: PropTypes.oneOf(['M', 'F']),
    phone_number: PropTypes.string,
    date_of_birth: PropTypes.number,
    height: PropTypes.number,
    weight: PropTypes.number,
    target_sex: PropTypes.oneOf(['M', 'F']),
    avatar_url: PropTypes.string,
    contact_info: PropTypes.string,
    email_verified: PropTypes.bool,
    active: PropTypes.bool,
  }),
  onSave: PropTypes.func,
  onRequestVerifyEmail: PropTypes.func,
}

EditProfileForm.defaultProps = {
  user: {},
  onSave: () => {},
  onRequestVerifyEmail: () => {},
}

export default EditProfileForm

import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from '@rebass/grid'
import Text from '../../atoms/Text'
import Button from '../../atoms/Button'
import Tooltip from '../../atoms/Tooltip'

const FIND_NEXT_REPLY_MESSAGE = '我仲想搵多個呀～'

const FindNextActionSection = ({ onSendMessage, disabled, ...props }) => {
  return (
    <Flex justifyContent="center" p="1rem" {...props}>
      <Flex flexDirection="column" alignItems="flex-end">
        <div id="find_next_anchor" style={{ width: 60 }} />
        <Button
          palette="secondary"
          onClick={() => onSendMessage(FIND_NEXT_REPLY_MESSAGE)}
          disabled={disabled}
        >
          <Text variant="h3" palette={disabled ? 'white' : 'grayscale'}>仲想搵多個 😎</Text>
        </Button>
        <Text variant="body2" palette="error">(訊息 - 5)</Text>
      </Flex>
      <Tooltip anchorId="find_next_anchor" place="top-start" defaultIsOpen>
        <Text palette="white" align="center">{'可以保留現有要求繼續搵！\n唔駛重新講一次！'}</Text>
      </Tooltip>
    </Flex>
  )
}

FindNextActionSection.propTypes = {
  onSendMessage: PropTypes.func,
  disabled: PropTypes.bool,
}

FindNextActionSection.defaultProps = {
  onSendMessage: () => {},
  disabled: false,
}

export default FindNextActionSection

import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { palette } from 'styled-theme'
import { Box, Flex } from '@rebass/grid'
import { GrPowerReset } from 'react-icons/gr'
import Text from '../../atoms/Text'
import Image from '../../atoms/Image'
import Button from '../../atoms/Button'
import Modal from '../../molecules/Modal'
import { handleErrorResponse } from '../../../utlils/general'
import { generateMyAvatar } from '../../../api/users'
import { useAuth } from '../../../contexts/auth'

const USER_AVATAR_GENERATION_COST = 10

const AvatarFrame = styled(Flex)`
  position: relative;
  width: 50%;
  min-width: 80px;
  max-width: 160px;
  aspect-ratio: 1;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 2px solid ${palette('primary', 0)};
  background-color: ${palette('secondary', 5)};
`

const GenerateButton = styled(Button)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
`

const AvatarSection = ({ onTopUp }) => {
  const { auth, setAuth, setAuthUser } = useAuth()
  const [generating, setGenerating] = useState(false)
  const [regenModalOpen, setRegenModalOpen] = useState(false)

  const avatarUrl = useMemo(() => {
    return auth.user?.avatar_url || null
  }, [auth.user])

  const generateAvatar = useCallback(async () => {
    try {
      setGenerating(true)
      const { data } = await generateMyAvatar({})
      setAuthUser((oriUser) => ({
        ...oriUser,
        avatar_url: data.url,
      }))
    } catch (err) {
      handleErrorResponse(err, setAuth, '有啲野出錯左！\n你可以改一改個人簡介或者等陣再試>.<')
    } finally {
      setGenerating(false)
    }
  }, [setAuth, setAuthUser])

  const onClickConfirmGenerate = useCallback(() => {
    const quotaRemaining = auth.user?.message_remaining
    if (quotaRemaining < USER_AVATAR_GENERATION_COST) {
      return onTopUp()
    }
    setRegenModalOpen(false)
    return generateAvatar()
  }, [auth.user, generateAvatar, onTopUp])

  return (
    <AvatarFrame>
      {avatarUrl ? (
        <Image
          key={avatarUrl}
          src={avatarUrl}
          width="100%"
          height="100%"
          circle
        />
      ) : (
        <Text style={{ fontSize: 80 }}>🤖</Text>
      )}
      <GenerateButton
        padding={0}
        disabled={generating}
        onClick={() => setRegenModalOpen(true)}
      >
        <GrPowerReset size={20} />
      </GenerateButton>
      {generating && (
        <Flex
          justifyContent="center"
          alignItems="center"
          style={{
            position: 'absolute',
            inset: 0,
            borderRadius: '50%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          <Image src="/logo_simple.png" height="3rem" className="self_rotate" />
        </Flex>
      )}
      <Modal
        isOpen={regenModalOpen}
        onClose={() => setRegenModalOpen(false)}
        showCloseBtn
      >
        <Flex
          width={1}
          alignItems="center"
          flexDirection="column"
          minWidth={240}
          maxWidth={360}
          p="1rem"
        >
          <Text variant="h3" align="center">
            Crush仔🤖 會根據你嘅個人資料，為你度身訂造一個頭像，需要10個用量
          </Text>
          <Flex mt="2rem" width={1} maxWidth="14.5rem">
            <Box flex={1}>
              <Button
                palette="error"
                variant="outline"
                onClick={() => setRegenModalOpen(false)}
                fullWidth
              >
                取消
              </Button>
            </Box>
            <Box flex={1} ml="1rem">
              <Button onClick={onClickConfirmGenerate} fullWidth>
                去馬！
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Modal>
    </AvatarFrame>
  )
}

AvatarSection.propTypes = {
  onTopUp: PropTypes.func,
}

AvatarSection.defaultProps = {
  onTopUp: () => {},
}

export default AvatarSection
